import React, { useEffect, useState } from "react"

export default function Home({location, pageContext}) {
  const [casinos, setCasinos] = useState(pageContext.coffeeShops)
  // const [casinos, setCasinos] = useState([]);


  // useEffect(() => {
  //   var formdata = new FormData();
  //   formdata.append("category", "FREESpins");
  //   var requestOptions = {
  //     method: 'POST',
  //     body: formdata,
  //   };

  //   fetch("https://www.twostones.in/casino_app/public/api/casino", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       setCasinos(result)
  //     })
  //     .catch(error => console.log('error', error));
  // }, [])

  return (
    <>
      <ul>
        {
          casinos.length > 0 ? 
            casinos.map((i) => {
              return <li>{i.name}</li>
            })
          : 'No Casinos Found'
        }
      </ul>
    </>
  )
}
